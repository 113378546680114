import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { graphql, Link, StaticQuery } from "gatsby";

import Hero from "../../components/landingPage/Hero";
import Layout from "../../components/Layout";
import Cta from "../../components/Cta";

import Location from "../../components/landingPage/Location";
import PriceList from "../../components/landingPage/PriceList";
import Reviews from "../../components/landingPage/Reviews";
import Dermoscopy from "../../components/landingPage/Dermoscopy";
import Oncology from "../../components/landingPage/Oncology";
import Videodermoscopy from "../../components/landingPage/Videodermoscopy";
import Surgery from "../../components/landingPage/Surgery";
import Melanoma from "../../components/landingPage/Melanoma";
import Abcde from "../../components/landingPage/Abcde";
import Gallery from "../../components/landingPage/Gallery";
import Offer from "../../components/landingPage/Offer";
import Faq from "../../components/landingPage/Faq";
import RiskGroup from "../../components/landingPage/RiskGroup";
import Covid from "../../components/landingPage/Covid";
import ImportantInfo from "../../components/landingPage/ImportantInfo";
import Contact from "../../components/landingPage/Contact";
import ProcedureDetails from "../../components/landingPage/ProcedureDetails";
import WhyUs from "../../components/landingPage/WhyUs";

//styles
import "../../styles/home.scss";

import SEO from "../../components/SEO";
import CallCta from "../../components/CallCta";
import Button from "../../components/Button";
import ButtonDrw from "../../components/landingPage/ButtonDrw";
import {
  GTM_WIDEO,
  GTM_CHIRURGIA,
  GTM_DERMATOLOGIA,
  GTM_E_ONKOLOGIA,
  GTM_NIECHIRURGIA,
  GTM_ONKOLOGIA,
  GTM_LEAD_UMOW_SIE,
} from "../../config/gtm_classes";
import ButtonApptNow from "../../components/landingPage/ButtonApptNow";
import Surgery2 from "../../components/landingPage/Surgery2";
import Laser from "../../components/landingPage/Laser";

const GTM_LOCATION = "wroclaw-laserowe";

const REVIEWS = [
  {
    author: "Bożena G.",
    city: "Wrocław",
    review:
      "Ode mnie 5/5. Jedyne takie miejsce, gdzie bez problemu można umówić się na badanie wszystkich znamion, a mam ich naprawdę dużo. Na szczęście nic nie wykazało.",
  },
  {
    author: "Jarek O.",
    city: "Wrocław",
    review:
      "Dziękuję Pani dr za szybki termin wizyty i za tempo wszystkich przeprowadzonych badań oraz możliwość zdalnej konsultacji z onkologiem.",
  },
  {
    author: "Iga J.",
    city: "Wrocław",
    review:
      "Przeprowadzone badanie dermatoskopowe nie wykazało nic złego. Badanie wykonywane skrupulatnie. Świetne podejście do klienta. Wszyscy mili i uśmiechnięci. Cena konkurencyjna.",
  },
];


const PRICES = [
  {
    isOpened: false,
    categoryName: "Laserowe usuwanie zmian skórnych",
    categorySub: "m.in brodawki, włókniaki, kurzajki",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_NIECHIRURGIA}`}
        data-apptnow-widget="2fc13440-f71c-11ee-8bd9-25a7ba62e9a8"
      />
    ),
    items: [
      {
        price: "350",
        label: "usunięcie 1 zmiany skórnej do 0,5 cm",
        sub: "w standardowej lokalizacji - tułów/ kończyny",
      },
      {
        price: "50",
        label:
          "*usunięcie dodatkowej zmiany skórnej w trakcie tej samej wizyty do 0,5 cm",
        sub: "w standardowej lokalizacji - tułów/ kończyny",
      },
      {
        price: "400",
        label:
          "usunięcie 1 zmiany powyżej 0,5 cm lub w szczególnej lokalizacji np. twarz/ włosy",
      },
      {
        price: "100",
        label:
          "*usunięcie dodatkowej zmiany powyżej 0,5 cm lub w szczególnej lokalizacji np. twarz/ włosy",
      },
      {
        price: "400",
        label:
          "usunięcie 1 brodawki wirusowej - kurzajki",
      },
      {
        price: "100",
        label:
          "*usunięcie dodatkowej brodawki wirusowej - kurzajki w trakcie tej samej wizyty",
          sub: "w przypadku rozległych zmian o liczbie zabiegów decyduje lekarz",
      },
      {
        price: "200",
        label: "konsultacja lekarska",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Badanie znamion - wideodermatoskopia",
    categorySub: "Dołącz do naszego <strong>programu profilaktyki</strong> po wizycie, aby korzystać z <strong>niższych cen</strong>. Więcej informacji otrzymasz mailowo w wiadomości potwierdzającej wizytę.",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_NIECHIRURGIA}`}
        data-apptnow-widget="f9357dc0-2aab-11ed-90c1-35915239b961"
      />
    ),

    items: [
      {
        price: "400",
        label: "wideodermatoskopia wszystkich znamion - osoba dorosła",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "300",
        label:
          "wideodermatoskopia wszystkich znamion - dziecko do 12 roku życia",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "250",
        label:
          "wideodermatoskopia - wizyta kontrolna do 6 mies. od pełnego badania",
        sub:
          "badanie wytypowanych przez lekarza na poprzedniej wizycie znamion do monitorowania",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Chirurgiczne usuwanie znamion",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_NIECHIRURGIA}`}
        data-apptnow-widget="0ecf46c0-2aac-11ed-90c1-35915239b961"
      />
    ),
    items: [
      {
        price: "200",
        label: "konsultacja chirurgiczna",
        sub: "zabiegi chirurgiczne są wykonywane u pacjentów od 12 r.ż.",
      },
      {
        price: "480",
        label: "usunięcie 1 znamienia do 1,5cm",
        sub:
          "w standardowej lokalizacji - tułów/kończyny - w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "280",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty do 1,5cm",
        sub: "w standardowej lokalizacji - tułów/kończyny",
      },
      {
        price: "580",
        label:
          "usunięcie 1 znamienia powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
        sub: "w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "350",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
      },
      {
        price: "150",
        label: "badanie histopatologiczne jednej zmiany",
      },
      {
        price: "300",
        label: "biopsja zmiany skórnej",
        
      },
      {
        price: "0",
        label: "wizyta kontrolna po zabiegu ze zdjęciem szwów",
        sub: "*dla pacjentów, którzy odbyli zabieg w Twoje Znamiona",
      },
      {
        price: "100",
        label: "zdjęcie szwów",
        sub: "*dla pacjentów, którzy wykonali zabieg poza Twoje Znamiona",
      },
    ],
  },
  
  {
    isOpened: false,
    categoryName: "Konsultacje onkologiczne - czerniak",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_E_ONKOLOGIA}`}
        data-apptnow-widget="57c029a0-9b25-11ed-b366-89b05425b063"
      />
    ),
    items: [
      {
        price: "200",
        label: "e-wizyta - wideokonferencja",
      },
    ],
  },
  // {
  //   isOpened: false,
  //   categoryName: "Konsultacje dermatologiczne",
  //   items: [
  //     {
  //       price: "250",
  //       label: "konsultacja dermatologiczna",
  //       sub: "inne schorzenia skóry: trądzik, łuszczyca, grzybica itp.",
  //       button: () => (
  //         <ButtonApptNow
  //           className={`${GTM_LOCATION} ${GTM_NIECHIRURGIA}`}
  //           data-apptnow-widget="25c878b0-2aac-11ed-90c1-35915239b961"
  //         />
  //       ),
  //     },
  //   ],
  // },
];
const OFFERS = [
  {
    type: "niechirurgiczne",
    title: "Zabiegi laserowe",
    desc:
      "Nieinwazyjne zabiegi laserowe usuwania zmian skórnych pomogą Ci pozbyć się zmian takich jak włókniaki, brodawki czy kurzajki. Laserowe usuwanie pieprzyków jest zarezerwowane dla zmian łagodnych, a kwalifikację do zabiegu wykonuje lekarz.",
      link:"#cennik",
  },
  {
    type: "chirurgiczne",
    title: "Usuwanie znamion",
    desc:
      "Każda niepokojąca zmiana skórna powinna być usunięta chirurgicznie. Zabieg polega na wycięciu zmiany wraz z fragmentem zdrowej skóry. W każdym przypadku wykonywane jest badania histopatologiczne, celem postawienia jednoznacznej diagnozy. ",
      link:"/wroclaw/usuwanie-znamion-pieprzykow",
  },
  {
    type: "badanie",
    title: "Badanie znamion",
    desc:
      "Wideodermatoskopowe badanie wszystkich znamion z mapowaniem zmian wytypowanych przez lekarza do obserwacji i usunięcia pomoże Ci zachować zdrowie, a powiększony dermatoskopowy obraz pomoże lekarzowi w wyborze odpowiedniej metody usunięcia Twoich zmian skórnych.",
      link:"/wroclaw/dermatoskopowe-badanie-znamion-wideodermatoskopia",
  },
];

const INFOS = [
  {
    title: "Ile trwa zabieg?",
    desc:
      "Zabieg laserowego usuwania zmian skórnych jest krótki, nie wymaga pobytu w szpitalu, trwa zwykle ok. 10 - 20 min.",
  },
  {
    title: "Jakie zmiany możemy usuwać laserowo?",
    desc:
      "Metoda laserowego usuwania znamion jest zarezerwowana dla zmian łagodnych takich m.in jak brodawki, włókniaki, kurzajki, kłykciny czy kępki żółte.",
  },
  {
    title: "Czy zabieg laserowy boli?",
    desc:
      "Zazwyczaj jest to zabieg niebolesny, może powodować uczucie lekkiego pieczenia lub mrowienia, możliwe jest też zastosowanie znieczulenia miejscowego.",
  },
];

const CtaUmowSie = () => {
  return (
    <AnchorLink href="#cennik" title="Umów się" className={GTM_LEAD_UMOW_SIE}>
      Umów się
    </AnchorLink>
  );
};
const CtaCennik = () => {
  return (
    <AnchorLink href="#cennik" title="Cennik">
      Ile to kosztuje?
    </AnchorLink>
  );
};

const PoznanBadanieZnamionPage = ({ graph, location }) => {
  const FAQS = [
    {
      image: graph.faqs[1].image,
      imageMobile: graph.faqs[1].imageMobile,
      title:
        "Czy laserowe usuwanie znamion zostawi blizny?",
      text:
        "Zastosowanie lasera CO2 minimalizuje ryzyko powstawania blizn, dzięki precyzyjnemu i kontrolowanemu procesowi leczenia. Po zabiegu zazwyczaj pozostają niewielkie zaczerwienienia, które stopniowo zanikają.",
    },
    {
      image: graph.faqs[2].image,
      imageMobile: graph.faqs[2].imageMobile,
      title: "Czy muszę powtarzać zabieg, aby uzyskać trwałe efekty?",
      text:
        "W większości przypadków jednorazowe usuwanie jest skuteczne, jednak w zależności od rodzaju zmiany i indywidualnych czynników może być konieczne powtórzenie zabiegu. Lekarz oceni to podczas konsultacji.",
    },
  ];

  return (
    <Layout bg_color="purple" constactCtaComponent={<CtaUmowSie />} location={location}>
      <SEO
        title="Laserowe Usuwanie Zmian Skórnych w Wrocławiu - twojeznamiona.pl"
        description="Laserowe Usuwanie Zmian Skórnych w Wrocławiu - Od precyzyjnej diagnostyki po bezpieczne i nieinwazyjne usuwanie znamion, pieprzyków, brodawek, włókniaków, kurzajek i innych łagodnych zmian skórnych. Zaufaj specjalistom w dziedzinie diagnostyki zmian skórnych."
        keywords="usuwanie laserowe, nieinwazyjne, usuwanie kurzajek, brodawek, włókniaków, znamion, pieprzykow, piegów, pypciów, usuwanie brodawek z twarzy, wymrażanie kurzajek, wymrazanie brodawek"
      />
      <Hero
        h1="Laserowe Usuwanie Zmian Skórnych w Wrocławiu"
        oneLiner="Od precyzyjnej diagnostyki po bezpieczne i nieinwazyjne usuwanie znamion, pieprzyków, brodawek, włókniaków, kurzajek i innych łagodnych zmian skórnych."
        oneLinerMore="Zaufaj specjalistom w dziedzinie diagnostyki zmian skórnych.Wszystkie swoje działania skupiamy na kompleksowej diagnostyce znamion, aby wyeliminować nowotwory skóry z życia milionów ludzi."
        ctaPrimary={<CtaUmowSie />}
        ctaSecondary={<CtaCennik />}
        type="laser"
      />
      <Offer className="home_offer" data={OFFERS} />
      <ImportantInfo
        h2="Laserowe Usuwanie Zmian Skórnych"
        className="home_important_info"
        data={INFOS}
      />

      <PriceList className="home_price_list" data={PRICES} />

      <Contact className="home_contact" where="w Wrocławiu" city="Wroclaw" />
      <Location
        className="home_location"
        coords={{
          lat: 51.093245408381726,
          lng: 16.979683331779917,
        }}
        defaultZoom={18}
        googleMarker={{
          data: ["ul. Al. Gen. Józefa Hallera 190", "(lokal na parterze)"],
          link:
            "https://www.google.com/maps/dir//51.0932504,16.9796659/@51.0932529,16.9794674,218m/data=!3m1!1e3!4m2!4m1!3e0?entry=ttu", //https://g.page/twoje-znamiona-wroclaw?share
        }}
        additionalDetails="Centrum Badania Znamion znajduje się w bardzo bliskiej lokalizacji FAT. Lokal znajduje się obok perfumerii internetowej “Galon”. Kierując się od strony ul. Al. Gen. Józefa Hallera (przy Parku Grabiszyńskim) należy skręcić przy serwisie rowerowym, możliwy parking przy punkcie ALAB Laboratoria. Kierując się od strony FAT/ul. Grabiszyńska - za stacją benzynową, ok. 25 metrów od Żabki przy ul. Grabiszyńskiej 200. "
        locationInfo={[
          "Centrum Medyczne Medify.me",
          "ul. Al. Gen. Józefa Hallera 190 (lokal na parterze)",
          "dzielnica Grabiszyn",
          "53-203 Wrocław",
        ]}
        hoursInfo={["pon. - pt.: 08:00 - 20:30,", "sob. 9:00-14:00"]}
        transportationInfo={[
          "Najbliższe przystanki: Fiołkowa, FAT.",
          "W bliskiej okolicy Park Grabiszyński, Galeria Tarasy Grabiszyńskie, Laboratoria ALAB, Ogrody Grabiszyńskie",
        ]}
      />
      <Gallery
        className="home_gallery"
        data={graph.gallery}
        city="wroclaw"
        imageTitlePostfix="we Wrocławiu"
      />

      <Laser
        className="home_two_col_section_left_img with_no_padding_top"
        reverse={false}
      />

      <Reviews className="home_reviews" data={REVIEWS} />
      <Faq className="home_faq with_padding_top_bottom" data={FAQS} />
      <Surgery className="home_two_col_section_left_img" reverse={true} />
      <Melanoma className="home_melanoma" />
      <Dermoscopy
        className="home_two_col_section_right_img with_no_padding_top"
        reverse={true}
      />

      <Cta className="home_cta" constactCtaComponent={<CtaUmowSie />} />
      <CallCta isLanding />
    </Layout>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        ImageGallery1: allFile(
          filter: { relativePath: { regex: "/wroclaw1.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery2: allFile(
          filter: { relativePath: { regex: "/wroclaw2.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery3: allFile(
          filter: { relativePath: { regex: "/wroclaw3.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery4: allFile(
          filter: { relativePath: { regex: "/wroclaw4.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1Image: allFile(filter: { relativePath: { regex: "/faq1.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2Image: allFile(filter: { relativePath: { regex: "/faq4.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq-1-mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq-4-mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <PoznanBadanieZnamionPage
        graph={{
          gallery: [
            data.ImageGallery1.nodes[0],
            data.ImageGallery2.nodes[0],
            data.ImageGallery3.nodes[0],
            data.ImageGallery4.nodes[0],
          ],
          faqs: {
            1: {
              image: data.Faq1Image.nodes[0],
              imageMobile: data.Faq1ImageMobile.nodes[0],
            },
            2: {
              image: data.Faq2Image.nodes[0],
              imageMobile: data.Faq2ImageMobile.nodes[0],
            },
          },
        }}
        {...props}
      />
    )}
  />
);
